
import Sidebar from './components/menu/index.vue'
import Header from './components/header/index.vue'
import Tabbar from "./components/tabbar/index.vue";
import Footer from './components/footer/index.vue'
import {Component, Vue} from "vue-property-decorator";
// @ts-ignore
import variables from "@/assets/styles/variables.scss";

@Component({
  components: {Sidebar, Header, Tabbar, Footer},
  computed:{
    variables(){
      return variables;
    },
    cachedViews(){
      return this.$store.state.tagsView.cachedViews;
    },
    key(){
      return this.$route.path;
    }
  },

})
export default class Layout extends Vue {

  handleClickOutside() {
    this.$store.dispatch('app/closeSideBar', {withoutAnimation: false})
  }
}
